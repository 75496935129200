import { lazy } from 'react';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import { Form as FormulaForm, useAtomicFormSafe } from '@visma/formula';
import config from '@visma/public.config';
import { HasRole, IsAuthenticated } from '@visma/react-keycloak';
import Page from 'components/layouts/Page';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams, useLocation } from 'react-router-dom';
import { path as home } from '../home';
import FormStatus from './FormStatus';
import usePrefillKeycloakFormData from './usePrefillKeycloakFormData';
import parent from './_path';
const LanguageProvider = lazy(() => import('./LanguageProvider'));

export const path = `${parent}/:id`;

const allAvailableLanguages = process.env.REACT_APP_LOCALES ?? [];

const UserNotAuthenticatedAlert = function () {
  return (
    <Alert severity="info">
      <FormattedMessage
        defaultMessage={
          'Tunnistaudu vahvasti "Kirjaudu sisään"-painikkeen kautta, jotta lomake avautuu.'
        }
      />
    </Alert>
  );
};

const FormNotFoundAlert = function () {
  return (
    <Alert severity="error" style={{ marginBottom: 10 }}>
      <FormattedMessage defaultMessage={'Lomaketta ei löydetty.'} />
    </Alert>
  );
};

const getFormDataFromSearch = (searchString) => {
  try {
    const decodeString = decodeURI(searchString);
    const paramObject = {};
    decodeString
      .substring(1)
      .split('&')
      .forEach((row) => {
        const rowArr = row.split('=');
        paramObject[rowArr[0]] = rowArr.slice(1).join('=');
      });
    if (paramObject['formData']) {
      return JSON.parse(paramObject['formData']);
    } else {
      return {};
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    return {};
  }
};

const useAllowedLanguages = (form) => {
  const locale = useIntl().locale;
  const allowedLanguagesAreSet = !!(
    form?.meta?.allowedLanguages && form.meta.allowedLanguages.length > 0
  );
  return allowedLanguagesAreSet
    ? !form.meta.allowedLanguages.includes(locale)
    : false;
};

export const component = function Form() {
  const { id } = useParams();
  const { search } = useLocation();
  const [error, form] = useAtomicFormSafe(id);
  let formData = undefined;

  if (search && search !== '') {
    formData = getFormDataFromSearch(search);
  }

  const formElement = useAllowedLanguages(form) ? (
    <LanguageProvider
      locale={
        allAvailableLanguages.includes(form.meta.allowedLanguages[0])
          ? form.meta.allowedLanguages[0]
          : 'fi-FI'
      }
    >
      <FormulaForm
        id={id}
        useLoadFormData={usePrefillKeycloakFormData}
        {...(formData ? { formData: formData } : {})}
        reviewProps={{
          showSuccessText: true,
          hideNotAnswered: true,
          actions: (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={home}
              replace
            >
              <FormattedMessage defaultMessage="Sulje" />
            </Button>
          ),
        }}
      />
    </LanguageProvider>
  ) : (
    <FormulaForm
      id={id}
      useLoadFormData={usePrefillKeycloakFormData}
      {...(formData ? { formData: formData } : {})}
      reviewProps={{
        showSuccessText: true,
        hideNotAnswered: true,
        actions: (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={home}
            replace
          >
            <FormattedMessage defaultMessage="Sulje" />
          </Button>
        ),
      }}
    />
  );

  return (
    <Page title={form ? form.title : ''}>
      {form && (
        <Container maxWidth="xl">
          <HasRole resource={{ [config.keycloak.clientId]: 'configurer' }}>
            <FormStatus status={form.status} />
          </HasRole>
          {form.publicForm ? (
            formElement
          ) : (
            <IsAuthenticated fallback={<UserNotAuthenticatedAlert />}>
              {formElement}
            </IsAuthenticated>
          )}
        </Container>
      )}
      {error && (
        <Container maxWidth="md">
          <FormNotFoundAlert />
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={home}
            replace
          >
            <FormattedMessage defaultMessage="Palaa etusivulle" />
          </Button>
        </Container>
      )}
    </Page>
  );
};
